<script setup>
const props = defineProps(["liked", "user_id"])
const route = useRoute()
const city = useCity()
const page = ref(1)
const size = ref(20)
const total = ref(0)
const loading = ref(false)
const lastQuery = ref()
const banners = useBanners()

// Watch for changes in query parameters
watch(() => [route.query, page], ([newQuery, newPage], [oldQuery, oldPage]) => {
    if (newQuery.q != oldQuery.q) {
        fetchData(newQuery, 1)
    } else {
        fetchData(newQuery, newPage.value)
    }
}, { deep: true })

const data = ref([])
onMounted(() => {
    fetchData(route.query, 1);
})

watch(city, () => fetchData(route.query, 1))

async function fetchData(queryParams, newPage) {
    let params = { page: newPage || page.value, size: size.value }
    if (props.user_id) {
        params.user_id = props.user_id
    }
    if (props.liked) {
        params.liked = props.liked
    }

    let q = {
        ...useClone(queryParams),
        ...params,
        city: city.value,
    }

    if (JSON.stringify(lastQuery.value) == JSON.stringify(q)) {
        return
    }
    lastQuery.value = q

    loading.value = true
    await $fetch("/pub/adverts", {
        params: q,
    }).catch(err => {
        console.log(err)
    }).then(res => {
        if (res.liked?.length) {
            let likedMap = {}
            for (let liked of res.liked) {
                likedMap[liked.advert_id] = true
            }
            for (let item of res.items) {
                item._liked = !!likedMap[item.id]
            }
        }

        data.value = res.items
        total.value = res.total
        loading.value = false
    })
}
</script>
<template>
    <v-row v-if="loading">
        <v-col>
            <v-progress-circular color="primary" indeterminate />
        </v-col>
    </v-row>
    <template v-else>
        <v-row>
            <v-col class="text-subtitle-2 text-medium-emphasis font-weight-bold">
                Илэрц: {{ total }} илэрц
            </v-col>
        </v-row>
        <v-row v-if="data?.length">
            <AdvertCard v-for="item in data" :card="item" :key="item.uuid" />
        </v-row>
    </template>
    <v-row v-if="banners['footer']">
        <v-col cols="12">
            <v-carousel hide-delimiters height="auto" :show-arrows="false">
                <NuxtLink v-for="b in banners['footer']" :href="b.link" target="_blank" class="banner-link">
                    <v-carousel-item :src="$vuetify.display.smAndDown ? b.phone_image_url : b.com_image_url" :lazy-src="$vuetify.display.smAndDown ? b.phone_thumbnail_url : b.com_thumbnail_url" />
                </NuxtLink>
            </v-carousel>
        </v-col>
    </v-row>
    <v-row>
        <v-col cols="12">
            <v-pagination :total-visible="10" :length="Math.ceil(total / size)" v-model="page"></v-pagination>
        </v-col>
    </v-row>
</template>